<template>
  <div>
    <!-- SECTION modal-modify-add-infant -->
    <validation-observer
      ref="refFormObserver"
      #default="{ invalid, pristine }"
    >
      <b-modal
        id="modal-modify-add-infant"
        :title="$t('reservation.addInfant')"
        title-class="text-airline font-medium-4 font-weight-bolder"
        header-bg-variant="light-info"
        centered
        size="lg"
        no-close-on-backdrop
        @show="showHandle"
      >
        <template #modal-footer="{ cancel }">
          <div class="w-100 text-center">
            <b-button
              size="md"
              variant="outline-secondary"
              class="center rounded-pill px-1 px-md-2"
              @click="cancel()"
            >
              {{ $t('reservation.back') }}
            </b-button>

            <!-- @click="submitAndCheckPriceHandle" -->
            <b-button
              v-if="!isEmpty(adultOption)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-gradient border-right-0 px-1 px-md-2 ml-75 ml-md-2"
              pill
              :disabled="invalid || pristine"
              @click="submitChangeInfoHandle"
            >
              <div class="d-flex-center px-25">
                {{ $t('reservation.confirmChange') }}
              </div>
            </b-button>
          </div>
        </template>

        <b-card
          v-if="!isEmpty(adultOption)"
          header-bg-variant="light-warning"
        >
          <template #header>
            <span class="text-warning font-weight-bolder">
              {{ $t('reservation.noteAddInfant') }}
            </span>
          </template>

          <b-row class="mx-0 mt-2">
            <b-col lg="12">
              <validation-provider
                #default="validationContext"
                name="Select Adult"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('reservation.selectAdult')} (*)`"
                  label-for="select-adult"
                >
                  <!-- :reduce="val => val.paxId" -->
                  <v-select
                    id="select-adult"
                    v-model="dataToAdd.parentPaxId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="adultOption"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    label="paxId"
                    :clearable="false"
                    :placeholder="$t('reservation.placeholderSelect')"
                  >
                    <template #option="data">
                      <span class="font-medium-1 font-weight-bolder">
                        {{
                          `${data.lastName} ${data.firstName} (${data.paxType})`
                        }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="font-medium-1 font-weight-bold">
                        {{
                          `${data.lastName} ${data.firstName} (${data.paxType})`
                        }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="3">
              <validation-provider
                #default="validationContext"
                name="Honorific"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('reservation.Title')} (*)`"
                  label-for=""
                >
                  <v-select
                    id="Honorific"
                    v-model="dataToAdd.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="honorificOptions"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    label="text"
                    :clearable="false"
                    style="height: 37.98px !important"
                    :placeholder="$t('reservation.placeholderSelect')"
                  >
                    <template #option="data">
                      <span class="font-medium-1 font-weight-bolder">
                        {{ $t(`reservation.titleOptions.INFANT.${data.text}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="font-medium-1 font-weight-bold">
                        {{ $t(`reservation.titleOptions.INFANT.${data.text}`) }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="4">
              <validation-provider
                #default="validationContext"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('reservation.Lastname')} (*)`"
                  label-for=""
                >
                  <b-form-input
                    v-model="dataToAdd.lastName"
                    class="font-weight-bold"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    :placeholder="$t('reservation.phLastName')"
                    lazy-formatter
                    :formatter="formatterInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="5">
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('reservation.Firstname')} (*)`"
                  label-for=""
                >
                  <b-form-input
                    v-model="dataToAdd.firstName"
                    class="font-weight-bold"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                    :placeholder="$t('reservation.phFirstName')"
                    lazy-formatter
                    :formatter="formatterInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <!-- FIXME
                - Em bé:
                    minDate = ngày cuối từ hiện tại đến 2 năm,
                    maxDate = today
              -->
              <!-- <validation-provider
                #default="validationContext"
                name="Birthday"
                :rules="`required|ageGroup:OW,${bookingData.itineraries[0][0].departure.at},INFANT`"
              >
                <b-form-group
                  :label="`${$t('reservation.DoB')} (*)`"
                  label-for="dob"
                >
                  <b-form-datepicker
                    id="dob"
                    v-model="dataToAdd.birthday"
                    class="form-control overflow-hidden"
                    show-decade-nav
                    hide-header
                    locale="vi"
                    boundary="window"
                    no-flip
                    :label-help="''"
                    :state="getValidationState(validationContext) === false ? false: null"
                    :placeholder="`${$t('flight.dob')} (*)`"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :max="new Date()"
                    :min="new Date(1920, 0, 1)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider> -->
              <validation-provider
                #default="validationContext"
                :rules="`required|ageGroup:OW,${bookingData.itineraries[0][0].departure.at},INFANT`"
                :name="$t('flight.dob')"
                vid="dataToAdd-pax-birthday"
              >
                <b-form-group
                  id="dataToAdd-pax-birthday"
                  class="mb-0"
                >
                  <flat-pickr
                    v-model="dataToAdd.birthday"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      id="dataToAdd-pax-birthday"
                      v-model="dataToAdd.birthday"
                      :name="$t('flight.dob')"
                      class="form-control px-50 py-0"
                      :config="{
                        ...configFlatPickr,
                        altInputClass:
                          'form-control input px-50 py-0 border-transparent',
                      }"
                      :placeholder="$t('flight.dob')"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    target="dataToAdd-pax-birthday"
                    triggers="hover focus"
                    placement="top"
                    boundary="window"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <div v-else>
          <b-alert
            show
            variant="danger"
          >
            <div class="alert-body text-center text-danger">
              {{ $t('reservation.cantAddInfant') }}
            </div>
          </b-alert>

          <b-alert
            show
            variant="warning"
          >
            <div class="alert-body text-center text-warning">
              {{ $t('reservation.noteAddInfant_2') }}
            </div>
          </b-alert>
        </div>
      </b-modal>
      <!-- !SECTION -->
    </validation-observer>

    <!-- SECTION modal add infant pax info confirm price -->
    <!-- <b-modal
        id="modal-modify-pax-info-confirm-price"
        :title="$t('reservation.confirmInfoChange')"
        centered
        @show="showPriceHandle"
      >
        <template #modal-footer="{cancel}">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            :disabled="priceCancel === false"
            @click="submitChangeInfoHandle"
          >
            <div class="d-flex-center px-25">
              {{ $t('reservation.confirm') }}
            </div>
          </b-button>
        </template>

        <b-card
          class="mt-1"
        >
          <div v-if="priceCancel">
            <p>Danh xưng: <span class="font-weight-bolder">{{ priceCancel[0].title }}</span></p>
            <p>Họ: <span class="font-weight-bolder">{{ priceCancel[0].lastName }}</span></p>
            <p>Tên: <span class="font-weight-bolder">{{ priceCancel[0].firstName }}</span></p>
            <p class="text-right">
              Giá:
              <span class="text-airline font-weight-bolder">
                {{ formatCurrency(priceCancel[0].pricingInfo.total) }} (VND)
              </span>
            </p>
          </div>
          <div v-else-if="errorCancel">
            <div class="text-danger text-heading-4">
              {{ errorCancel }}
            </div>
          </div>
          <div v-else-if="priceCancel === false">
            {{ $t('reservation.loading') }}...
          </div>
        </b-card>
      </b-modal> -->
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import { isEmpty, cloneDeep } from 'lodash'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import VueI18n from '@/libs/i18n'

import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BCard,
    BAlert,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BTooltip,
    BFormDatepicker,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    adultOption: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const priceCancel = ref()
    const errorCancel = ref()

    const {
      getBookingData,
      // paxInfoPrice,
      modifyPaxInfo,
    } = useReservationHandle()

    const blankData = {
      firstName: '',
      lastName: '',
      title: '',
      paxType: 'INFANT',
      birthday: '',
      paxId: '',
      parentPaxId: '',
      familyId: '',
      source: '',
    }

    // const airlines = computed(() => getBookingData.value.airlines?.split(' | '))
    const dataToAdd = ref(cloneDeep(blankData))

    const resetContactDataToEdit = () => {
      dataToAdd.value = cloneDeep(blankData)
    }
    const { refFormObserver, getValidationState } = formValidation(
      resetContactDataToEdit,
    )

    function showHandle() {
      resetContactDataToEdit()
    }

    // function showPriceHandle() {
    //   const passenger = dataToAdd.value

    //   const updateData = {
    //     paxUpdates: [
    //       {
    //         paxInfo: {
    //           // gender: 'string',
    //           firstName: passenger.firstName,
    //           lastName: passenger.lastName,
    //           title: passenger.title,
    //           paxType: passenger.paxType,
    //           birthday: passenger?.birthday || null,
    //           paxId: passenger.paxId,
    //           parentPaxId: passenger.parentPaxId,
    //           familyId: passenger.familyId || passenger.parentPaxId,
    //         },
    //         operation: 'ADD',
    //         id: '600', // passenger.paxId,
    //       },
    //     ],
    //   }

    //   if (airlines.value.some(item => item === 'QH' || item === 'VJ')) {
    //     priceCancel.value = false
    //     errorCancel.value = null

    //     paxInfoPrice(getBookingData.value, updateData)
    //       .then(res => {
    //         if (!isEmpty(res.paxLists)) {
    //           priceCancel.value = res.paxLists
    //         } else {
    //           errorCancel.value = 'Lỗi thay đổi thông tin khách hàng, vui lòng thử lại sau'
    //         }
    //       })
    //       .catch(error => {
    //         errorCancel.value = error.response.data.message || error
    //       })
    //   }
    // }

    // // show modal check price
    // function submitAndCheckPriceHandle(bvModalEvent) {
    //   bvModalEvent.preventDefault()
    //   this.$bvModal.show('modal-modify-pax-info-confirm-price')
    // }

    // NOTE handle change info
    function submitChangeInfoHandle(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$bvModal.show('modal-api-loading')
      const passenger = dataToAdd.value

      const updateData = {
        paxUpdates: [
          {
            paxInfo: {
              firstName: passenger.firstName,
              lastName: passenger.lastName,
              title: passenger.title,
              paxType: passenger.paxType,
              birthday: passenger.birthday,
              paxId: ['1S', '1S_CTRL', 'VJ'].includes(
                getBookingData.value.source,
              )
                ? '1'
                : passenger.parentPaxId.paxId,
              parentPaxId: passenger.parentPaxId.paxId,
              familyId:
                passenger.parentPaxId.familyId
                || passenger.parentPaxId.parentPaxId,
              // pricingInfo: getBookingData.value.pricingInfo,
            },
            operation: 'ADD',
            id: '1',
          },
        ],
      }

      if (['VJ'].includes(getBookingData.value.source)) {
        updateData.paxUpdates.push({
          paxInfo: {
            firstName: passenger.parentPaxId.firstName,
            lastName: passenger.parentPaxId.lastName,
            title: passenger.parentPaxId.title,
            paxType: passenger.parentPaxId.paxType,
            birthday: passenger.parentPaxId.birthday,
            paxId: passenger.parentPaxId.paxId,
            parentPaxId: passenger.parentPaxId.parentPaxId,
            familyId:
              passenger.parentPaxId.familyId
              || passenger.parentPaxId.parentPaxId,
          },
          operation: 'UPDATE',
          id: '1',
        })
      }

      modifyPaxInfo(getBookingData.value, updateData)
        .then(() => {
          this.$bvModal.hide('modal-modify-add-infant')
          // this.$bvModal.hide('modal-modify-pax-info-confirm-price')
        })
        .catch(error => {
          errorCancel.value = error.response.data.message || error
        })
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    const honorificOptions = ['MISS', 'MSTR']

    // format input
    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().trim()
    }

    const locale = computed(() => VueI18n.locale).value

    const today = new Date()
    const getMinDate = new Date(
      today.getFullYear() - 2,
      today.getMonth(),
      today.getDate(),
    )

    const configFlatPickr = ref({
      dateFormat: 'Y-m-d',
      minDate: getMinDate,
      maxDate: 'today',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    return {
      getBookingData,
      dataToAdd,
      honorificOptions,
      formatterInput,
      configFlatPickr,

      // showPriceHandle,
      // submitAndCheckPriceHandle,
      showHandle,
      priceCancel,
      errorCancel,
      formatCurrency,
      submitChangeInfoHandle,
      convertISODateTime,

      isEmpty,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#Honorific ::v-deep {
  .vs__dropdown-toggle {
    height: 37.99px !important;
  }
}
</style>
